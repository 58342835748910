"use strict";
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Menu from "../administration/components/administration/menu";
const Home = () => {
  const [open, setOpen] = useState(true);
  return /* @__PURE__ */ jsx(Menu, { open, setOpen, children: /* @__PURE__ */ jsx(Outlet, {}) });
};
export default Home;
