import { IUser, IAddress } from '@administration/interfaces/IUser';
import { ICountryWithinAddresses } from '@administration/interfaces/ICountry';

export const extractCountryFromAddress = (): ICountryWithinAddresses | null => {
  const user: IUser | null = JSON.parse(localStorage.getItem('user') || 'null');
  const addresses: IAddress[] = user?.addresses || [];

  if (addresses.length === 0) {
    return null;
  }

  const primaryAddress = addresses.find((address) => address.primary);
  return primaryAddress ? primaryAddress.country : addresses[0].country;
};
