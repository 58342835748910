import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { instance } from './baseApi';

type AxiosBaseQueryArgs = {
  pathname: string;
  method?: AxiosRequestConfig['method'];
  data?: any;
  params?: Record<string, any>;
};

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosBaseQueryArgs, unknown, unknown> =>
  async ({ pathname, method = 'GET', data, params }) => {
    try {
      const response = await instance({
        url: pathname,
        method,
        data,
        params,
      });
      return { data: response.data };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        error: {
          status: axiosError.response?.status || 'FETCH_ERROR',
          data: axiosError.response?.data || axiosError.message,
        },
      };
    }
  };
