import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { TypeSuggestionsFilterName } from '../../interfaces/ISuggestions';
import { fetchSuggestions } from '../../pages/suggestions/slices/suggestion.slice';
import { SUGGESTION_PRIORITIES_MAPPER } from './utils/priorities_mapper';
import { RootState } from '@store/types';
import { IPagination } from '@administration/interfaces/IPagination';

const initialState: {
  user_type: 'admin' | 'client';
  pagination: IPagination;
  filterState: Record<
    TypeSuggestionsFilterName,
    { name: string; toggler: boolean; selectedFilters: any }
  >;
} = {
  user_type: 'client',
  pagination: {
    pageSize: 10,
    offset: 0,
  },
  filterState: {
    hcos: { name: 'hcos', toggler: false, selectedFilters: [] },
    hcps: { name: 'hcps', toggler: false, selectedFilters: [] },
    users: { name: 'users', toggler: false, selectedFilters: [] },
    products: { name: 'products', toggler: false, selectedFilters: [] },
    suggestion_priorities: { name: 'suggestion_priorities', toggler: false, selectedFilters: [] },
    hcp_priorities: { name: 'hcp_priorities', toggler: false, selectedFilters: [] },
    last_batch_only: { name: 'last_batch_only', toggler: false, selectedFilters: false },
    suggestion_date: {
      name: 'suggestion_date',
      toggler: false,
      selectedFilters: {
        start_date: null,
        end_date: null,
      },
    },
  },
};

export const onSubmitFilterSuggestions = createAsyncThunk(
  'suggestionsFilter/onSubmitFilterSuggestions',
  async (filter_type: 'pagination' | 'filter', { getState, dispatch }) => {
    const state = getState() as RootState;
    const filters = {
      hcos: state.suggestionsFilter.filterState.hcos.selectedFilters.map((item: any) => item.id),
      hcps: state.suggestionsFilter.filterState.hcps.selectedFilters.map((item: any) => item.id),
      users: state.suggestionsFilter.filterState.users.selectedFilters.map((item: any) => item.id),
      products: state.suggestionsFilter.filterState.products.selectedFilters.map(
        (item: any) => item.id,
      ),
      suggestion_priorities:
        state.suggestionsFilter.filterState.suggestion_priorities.selectedFilters.map(
          (item: string) => SUGGESTION_PRIORITIES_MAPPER[item],
        ),
      hcp_priorities: state.suggestionsFilter.filterState.hcp_priorities.selectedFilters,
      start_date: state.suggestionsFilter.filterState.suggestion_date.selectedFilters.start_date,
      end_date: state.suggestionsFilter.filterState.suggestion_date.selectedFilters.end_date,
    };

    await dispatch(
      fetchSuggestions({
        filters,
        pagination: state.suggestionsFilter.pagination,
        user_type: state.suggestionsFilter.user_type,
        last_batch_only: state.suggestionsFilter.filterState.last_batch_only.selectedFilters,
      }),
    );
    if (filter_type === 'filter' && state.suggestionsFilter.user_type === 'admin') {
      dispatch(
        setPagination({
          pageSize: state.suggestionsFilter.pagination.pageSize,
          offset: 0,
        }),
      );
    }
  },
);

const suggestionsFilterSlice = createSlice({
  name: 'suggestionsFilter',
  initialState,
  reducers: {
    toggle: (state, action: PayloadAction<TypeSuggestionsFilterName>) => {
      state.filterState[action.payload].toggler = !state.filterState[action.payload].toggler;
    },
    filterToggle: (
      state,
      action: PayloadAction<{
        filter: any;
        filterName: TypeSuggestionsFilterName;
      }>,
    ) => {
      switch (action.payload.filterName) {
        case 'suggestion_priorities':
        case 'hcp_priorities':
          state.filterState[action.payload.filterName].selectedFilters = state.filterState[
            action.payload.filterName
          ].selectedFilters.includes(action.payload.filter)
            ? state.filterState[action.payload.filterName].selectedFilters.filter(
                (f: any) => f !== action.payload.filter,
              )
            : [
                ...state.filterState[action.payload.filterName].selectedFilters,
                action.payload.filter,
              ];
          state.filterState[action.payload.filterName].toggler = true;
          break;
        case 'hcos':
        case 'hcps':
        case 'users':
        case 'products':
          state.filterState[action.payload.filterName].selectedFilters = action.payload.filter;
          state.filterState[action.payload.filterName].toggler = true;
          break;
        case 'suggestion_date':
          state.filterState.suggestion_date.selectedFilters.start_date =
            action.payload.filter.start_date;
          state.filterState.suggestion_date.selectedFilters.end_date =
            action.payload.filter.end_date;
          break;
        case 'last_batch_only':
          state.filterState.last_batch_only.selectedFilters = action.payload.filter;
          break;
        default:
          break;
      }
    },
    clearFilters: (state) => {
      Object.keys(state.filterState).forEach((key) => {
        if (key === 'suggestion_date') {
          state.filterState.suggestion_date.selectedFilters = {
            start_date: null,
            end_date: null,
          };
        } else if (key === 'last_batch_only') {
          state.filterState.last_batch_only.selectedFilters = false;
        } else {
          state.filterState[key as TypeSuggestionsFilterName].selectedFilters = [];
        }
      });
    },
    setUserType: (state, action: PayloadAction<'admin' | 'client'>) => {
      state.user_type = action.payload;
    },
    setPagination: (state, action: PayloadAction<{ pageSize: number; offset: number }>) => {
      state.pagination.pageSize = action.payload.pageSize;
      state.pagination.offset = action.payload.offset;
    },
  },
});

export const { toggle, filterToggle, clearFilters, setUserType, setPagination } =
  suggestionsFilterSlice.actions;
export default suggestionsFilterSlice.reducer;
