"use strict";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import suggestionsReducer from "@common/pages/suggestions/slices/suggestion.slice";
import themeReducer from "@common/theme/theme.slice";
import suggestionsFilterReducer from "@common/components/filters/filter.slice";
import usersReducer from "../common/slices/userSlice";
import { TenantApi } from "@administration/pages/administration/tenant/slices/tenant.api";
import { MappersApi } from "@administration/pages/administration/dashboard/veeva-setup/slices/mappers-api.slice";
import { OrganizationApi } from "@administration/components/administration/organization.api";
const rootReducer = combineReducers({
  suggestions: suggestionsReducer,
  theme: themeReducer,
  suggestionsFilter: suggestionsFilterReducer,
  users: usersReducer,
  [TenantApi.reducerPath]: TenantApi.reducer,
  [OrganizationApi.reducerPath]: OrganizationApi.reducer,
  [MappersApi.reducerPath]: MappersApi.reducer
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (gDM) => gDM().concat(TenantApi.middleware).concat(OrganizationApi.middleware).concat(MappersApi.middleware)
});
