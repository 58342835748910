import Cookies from 'universal-cookie';

export function cleanLocalStorage() {
  localStorage.removeItem('impersonated');
  localStorage.clear();
  sessionStorage.clear();
  handleDeleteAllCookies();
}

export function cleanLocalStorageExceptInit() {
  const backend_instance = localStorage.getItem('backend_instance');
  const theme = localStorage.getItem('theme');
  const displayTheme = localStorage.getItem('display_theme');
  const integrationMode = localStorage.getItem('integrationMode');

  localStorage.clear();
  sessionStorage.clear();
  theme && (theme === 'light' || theme === 'dark') && localStorage.setItem('theme', theme);
  displayTheme &&
    (displayTheme === 'light' || displayTheme === 'dark' || displayTheme === 'system') &&
    localStorage.setItem('display_theme', displayTheme);
  localStorage.setItem('backend_instance', backend_instance || '');
  integrationMode && localStorage.setItem('integrationMode', integrationMode);
  handleDeleteAllCookies();
}

export function cleanMeetingFromLocalStorage() {
  localStorage.removeItem('meetingId');
  localStorage.removeItem('transcriptId');
  localStorage.removeItem('noteId');
  localStorage.removeItem('novel__content');
  localStorage.removeItem('meetingInfo');
  localStorage.removeItem('user');
  localStorage.removeItem('user_role');
}

const handleDeleteAllCookies = () => {
  const cookies = new Cookies();
  const allCookies = cookies.getAll();
  for (const cookieName in allCookies) {
    cookies.remove(cookieName, { path: '/' });
  }
};
