import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';
import { IMappedTable, IMappedTableResponse } from '@administration/interfaces/IVeeva';

export const MappersApi = createApi({
  reducerPath: 'MappersApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMappedTables: builder.query({
      query: ({ crm }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/crms/${crm}/mappings/tables`,
      }),
      transformResponse: (response: IMappedTableResponse) => {
        if (!response?.tables?.length) {
          return {};
        }

        return response.tables.reduce(
          (acc: Record<string, IMappedTable[]>, table: IMappedTable) => {
            if (!table.destination_table || !table.crm_table) {
              return acc;
            }

            const category = table.category;
            if (!acc[category]) {
              acc[category] = [];
            }

            const exists = acc[category].some(
              (t) =>
                t.destination_table === table.destination_table && t.crm_table === table.crm_table,
            );

            if (!exists) {
              acc[category].push(table);
            }
            return acc;
          },
          {},
        );
      },
    }),
    getMappers: builder.query({
      query: ({ crm_table, destination_table }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/crms/veeva/mappings/search`,
        params: { crm_table, destination_table, page_size: 1000 },
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getAdminTables: builder.query({
      query: (orgId) => ({
        pathname: `/administration/organizations/${orgId}/crms/veeva/mappings/tables`,
      }),
      transformResponse: (response: any) => {
        if (!Array.isArray(response?.tables)) {
          return [];
        }
        const tablesSet = new Set(response.tables.map((t: any) => t.crm_table?.toLowerCase()));
        const tables = Array.from(tablesSet).map((t) => ({ name: t }));
        return tables;
      },
    }),
    getAdminTableColumns: builder.query({
      query: ({ orgId, table }) => ({
        pathname: `/administration/organizations/${orgId}/crms/veeva/mappings/tables/${table}`,
      }),
      transformResponse: (response: any) => {
        if (!Array.isArray(response?.tables)) {
          return [];
        }
        const columnsSet = new Set(response.tables.map((t: any) => t.source_column?.toLowerCase()));
        const columns = Array.from(columnsSet)
        return columns;
      },
    }),
    excuteQuery: builder.mutation<
      { headers: string[]; values: any[] },
      { orgId: string; query: string }
    >({
      query: ({ orgId, query }) => ({
        pathname: `/administration/organizations/${orgId}/providers/veeva/connections/api/query`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          query,
        },
      }),
      transformResponse: (response: { records?: Record<string, any>[] }) => {
        if (!response?.records || response.records.length === 0) {
          return { headers: [], values: [] };
        }
        const headers = Object.keys(response.records[0]);
        return { headers, values: response?.records };
      },
    }),
  }),
});

export const {
  useGetMappedTablesQuery,
  useGetMappersQuery,
  useGetAdminTablesQuery,
  useGetAdminTableColumnsQuery,
  useExcuteQueryMutation,
} = MappersApi;
