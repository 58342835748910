import { useEffect, useState } from 'react';
import { getPermissions } from '../apis/users';
import { IRules } from '@common/interfaces/IAcl';
import { extractCountryFromAddress } from '@common/helpers/addresses';

export const usePermissions = () => {
  const storedRole = localStorage.getItem('user_role');
  const initialRoles = storedRole ? JSON.parse(storedRole) : ['user'];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rules, setRules] = useState<IRules>({
    roles: initialRoles,
    userCountry: extractCountryFromAddress(),
  });

  useEffect(() => {
    const storedPermissions = localStorage.getItem('user_role');
    if (storedPermissions) {
      return;
    }

    const fetchPermissions = async () => {
      try {
        setIsLoading(true);
        const response = await getPermissions();
        if (response.status === 200) {
          setRules((prevRules) => ({
            ...prevRules,
            roles: response.data.permissions,
          }));
          localStorage.setItem('user_role', JSON.stringify(response.data.permissions));
        } else {
          setRules((prevRules) => ({
            ...prevRules,
            roles: ['user'],
          }));
          localStorage.setItem('user_role', JSON.stringify(['user']));
        }
      } catch (e) {
        setRules((prevRules) => ({
          ...prevRules,
          roles: ['user'],
        }));
        localStorage.setItem('user_role', JSON.stringify(['user']));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  return { rules, isLoading };
};
