import React from 'react';
import {
  Business as BusinessIcon,
  CardGiftcard as CardGiftcardIcon,
  GroupsOutlined as GroupIcon,
  PermIdentity as PermIdentityIcon,
  ListAlt as ListAltIcon,
  ManageAccountsOutlined as ManageAccountsIcon,
  SettingsSuggestOutlined as SettingsSuggestOutlinedIcon,
  Api as ApiIcon,
  AccountTreeOutlined as AccountTreeOutlinedIcon,
} from '@mui/icons-material';
export const drawerWidth = 240;
export const drawerWidthSmall = 240;

export const menuItems = [
  // {
  //   text: "Dashboard",
  //   icon: <SpaceDashboardIcon />,
  //   path: "/admin",
  //   index: 0,
  // },
  {
    level: 0,
    text: 'Packages',
    icon: <CardGiftcardIcon />,
    path: '/admin/packages',
    index: 1,
  },
  {
    level: 0,
    text: 'Features',
    icon: <ListAltIcon />,
    path: '/admin/features',
    index: 2,
  },
  {
    level: 0,
    text: 'Organizations',
    icon: <BusinessIcon />,
    path: '/admin/organizations',
    index: 3,
  },
  {
    level: 0,
    text: 'Users',
    icon: <PermIdentityIcon />,
    path: '/admin/users',
    index: 4,
  },
  {
    level: 0,
    text: 'Groups',
    icon: <GroupIcon />,
    path: '/admin/groups',
    index: 5,
  },
  {
    level: 0,
    text: 'Suggestions',
    icon: <SettingsSuggestOutlinedIcon />,
    path: '/admin/suggestions',
    index: 6,
    children: [
      {
        level: 1,
        text: 'Suggestions Weights',
        path: '/admin/suggestions/suggestions-weights',
        index: 61,
      },
      {
        level: 1,
        text: 'Suggestions list',
        path: '/admin/suggestions/list',
        index: 62,
      },
    ],
  },
  {
    level: 0,
    text: 'Audiences',
    icon: <ManageAccountsIcon />,
    path: '/admin/audiences',
    index: 7,
  },
  {
    level: 0,
    text: 'Organization demo',
    icon: <AccountTreeOutlinedIcon />,
    path: '/admin/tenants',
    index: 8,
  },
  {
    level: 0,
    text: 'Veeva',
    icon: <ApiIcon />,
    path: '/admin/veeva',
    index: 9,
    children: [
      {
        level: 1,
        text: 'Query',
        path: '/admin/veeva/query',
        index: 90,
      },
      {
        level: 1,
        text: 'Cycle',
        path: '/admin/veeva/cycle',
        index: 91,
      },
      {
        level: 1,
        text: 'Cycles Plans',
        path: '/admin/veeva/cycles-plans',
        index: 92,
      },
      {
        level: 1,
        text: 'Cycle Plans Targets',
        path: '/admin/veeva/cycle-plans-targets',
        index: 93,
      },
    ],
  },
];
