import { createApi } from '@reduxjs/toolkit/query/react';
import { IOrganization } from 'administration/common/interfaces/IOrganization';
import { axiosBaseQuery } from '@common/apis/axios-base-query';

export const OrganizationApi = createApi({
  reducerPath: 'OrganizationApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ORGANIZATION'],
  endpoints: (builder) => ({
    getOrganizations: builder.query<IOrganization[], void>({
      query: () => ({
        pathname: '/administration/organizations?expand=package,features,domains',
        method: 'GET',
      }),
      transformResponse: (response: any) => response.values,
      providesTags: ['ORGANIZATION'],
    }),
  }),
});

export const { useGetOrganizationsQuery } = OrganizationApi;
