import {
  Box,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Collapse,
  Typography,
} from '@mui/material';

import { Drawer as MuiDrawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link, useNavigate } from 'react-router-dom';
import { drawerWidth, menuItems } from '../../pages/administration/variables';
import './menu.css';
import React, { useCallback, useState } from 'react';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface MenuProps {
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: any;
  index?: number;
}
interface TogglerState {
  [key: string]: { isActive: boolean; isOpen: boolean };
}

const Menu = (props: MenuProps) => {
  const initializeTogglers = useCallback((links: any[]): TogglerState => {
    return links.reduce((acc, item) => {
      acc[item.text.toLowerCase()] = { isActive: false, isOpen: false };
      if (item.children) {
        Object.assign(acc, initializeTogglers(item.children));
      }
      return acc;
    }, {} as TogglerState);
  }, []);

  const [togglers, setTogglers] = useState<TogglerState>(initializeTogglers(menuItems));

  const handleToggle = useCallback((itemName: string) => {
    if (!itemName) {
      return;
    }

    setTogglers((prev) => {
      const newTogglers = {} as TogglerState;
      Object.entries(prev).forEach(([key, value]) => {
        const { isOpen } = value;
        if (key === itemName.toLocaleLowerCase()) {
          newTogglers[key] = {
            isActive: true,
            isOpen: !isOpen,
          };
        } else {
          newTogglers[key] = { isActive: false, isOpen };
        }
      });

      return newTogglers;
    });
  }, []);
  const navigate = useNavigate();

  const renderLinks = (links: any) => {
    return links.map((item: any) => {
      const hasChildren = item.children && item.children.length > 0;
      const isOpen = togglers[item.text.toLowerCase()].isOpen;
      const isActive = togglers[item.text.toLowerCase()].isActive;
      const content = (
        <React.Fragment key={item.index}>
          {item.children && (
            <ListItemButton
              key={item.text}
              className="administration-menu-entry"
              onClick={() => handleToggle(item.text)}
            >
              <ListItemIcon sx={{ color: 'text.primary' }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={<Typography variant="text-sm">{item.text}</Typography>}
                sx={{ color: 'text.primary', pl: item.level * 0.5 }}
              />
              {hasChildren &&
                (isOpen ? (
                  <RemoveIcon
                    sx={{
                      backgroundColor: 'primary.light',
                      borderRadius: '50px',
                      fontSize: '20px',
                      padding: '2px',
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      backgroundColor: 'primary.light',
                      borderRadius: '50px',
                      fontSize: '20px',
                      padding: '2px',
                    }}
                  />
                ))}
            </ListItemButton>
          )}
          {!item.children && (
            <ListItemButton
              className="administration-menu-entry"
              key={item.index}
              selected={isActive}
              onClick={() => {
                handleToggle(item.text);
                navigate(item.path);
              }}
            >
              <ListItemIcon sx={{ color: 'text.primary' }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={<Typography variant="text-sm">{item.text}</Typography>}
                sx={{ color: 'text.primary', pl: item.level * 0.5 }}
              />
            </ListItemButton>
          )}
        </React.Fragment>
      );

      return (
        <React.Fragment key={item.text}>
          {content}
          {hasChildren && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderLinks(item.children)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: 'background.light' }}>
      <Drawer
        variant="permanent"
        open={props.open}
        PaperProps={{
          sx: {
            backgroundColor: 'background.light',
            borderRight: 'none',
          },
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" pt={2}>
          <Link to="/">
            <img
              height="40px"
              width="40px"
              src={`${process.env.PUBLIC_URL}/assets/images/SB_logo_green.svg`}
              alt="secondbrain logo"
            />
          </Link>
          <Typography variant="text-md-b" pl={2}>
            SecondBrain
          </Typography>
        </Box>
        <List component="nav">{renderLinks(menuItems)}</List>
      </Drawer>
      <Box
        component="main"
        sx={{
          // backgroundColor: (theme) =>
          //   theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          backgroundColor: 'background.light',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          py: 2,
          pr: 2,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: '100%',
            maxHeight: 'fit-content',
            p: 2,
            backgroundColor: 'common.white',
            borderRadius: '12px',
          }}
        >
          {props.children}
        </Container>
      </Box>
    </Box>
  );
};

export default Menu;
