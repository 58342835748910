import { AxiosResponse } from 'axios';
import { config } from '../config/configurations';
import { instance } from './baseApi';
import { IFilter } from '../interfaces/IFilters';
import { IPagination } from '@administration/interfaces/IPagination';

export function getSuggestions(): Promise<AxiosResponse> {
  return getMySuggestions();
}

export function getAllSuggestions(): Promise<AxiosResponse> {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsDict: any = {};
  searchParams.forEach(function (value, key) {
    paramsDict[key] = value;
  });

  return instance.get(`/${config.REACT_APP_API_VERSION}/suggestions`, {
    params: searchParams,
  });
}

export function getMySuggestions(): Promise<AxiosResponse> {
  const searchParams = new URLSearchParams(window.location.search);
  const paramsDict: any = {};
  searchParams.forEach(function (value, key) {
    paramsDict[key] = value;
  });

  return instance.get(`/${config.REACT_APP_API_VERSION}/me/suggestions`, {
    params: searchParams,
  });
}

export function computeSuggestions(id: string | null, flag: string): Promise<AxiosResponse> {
  if (!id && flag !== 'ALL') {
    return Promise.reject('Id is required');
  }
  let payload;
  if (flag === 'COUNTRY') {
    payload = { country: id };
  } else if (flag === 'PRODUCT') {
    payload = { product: id };
  } else if (flag === 'USER') {
    payload = { user: id };
  } else if (flag === 'USECASE') {
    // to be implemented
    payload = { usecase: id };
  } else if (flag === 'ALL') {
    payload = {};
  }

  return instance.post(`/${config.REACT_APP_API_VERSION}/suggestions/compute`, payload);
}

export function deleteSuggestion(id: string, flag: string): Promise<AxiosResponse> {
  let url = '';

  if (flag === 'ALL') {
    url = `/${config.REACT_APP_API_VERSION}/suggestions?delete=all`;
    return instance.delete(url);
  }
  if (flag === 'COUNTRY') {
    url = `/${config.REACT_APP_API_VERSION}/suggestions?delete=country&country_id=${id}`;
    return instance.delete(url);
  }
  if (flag === 'USER') {
    url = `/${config.REACT_APP_API_VERSION}/suggestions?delete=user&user_id=${id}`;
    return instance.delete(url);
  }
  if (flag === 'VEEVA_SUGG') {
    url = `${config.REACT_APP_API_VERSION}/providers/veeva/connections/api/suggestions/bulk`;
    return instance.delete(url, {
      params: {
        user_id: id,
      },
    });
  }
  if (flag === 'PRODUCT') {
    url = `/${config.REACT_APP_API_VERSION}/suggestions?delete=product&product_id=${id}`;
    return instance.delete(url);
  }

  return Promise.reject(new Error('Invalid flag provided'));
}

export function dismissSuggestion(id: string): Promise<AxiosResponse> {
  return instance.put(`/${config.REACT_APP_API_VERSION}/suggestions/${id}/dismiss`);
}

export function completeSuggestion(id: string): Promise<AxiosResponse> {
  return instance.put(`/${config.REACT_APP_API_VERSION}/suggestions/${id}/complete`);
}

export function enableSuggestion(id: string): Promise<AxiosResponse> {
  return instance.put(`/${config.REACT_APP_API_VERSION}/suggestions/${id}/enable`);
}

export function getSuggestionsStatistics(): Promise<AxiosResponse> {
  return instance.get(`/${config.REACT_APP_API_VERSION}/me/suggestions/statistics`);
}

export function searchSuggestions({
  filters,
  pagination,
  user_type = 'client',
  last_batch_only = true,
}: {
  filters: IFilter | null;
  pagination: IPagination;
  user_type: string;
  last_batch_only: boolean | undefined;
}): Promise<AxiosResponse> {
  const url =
    user_type === 'client'
      ? `/${config.REACT_APP_API_VERSION}/me/suggestions/search`
      : `/${config.REACT_APP_API_VERSION}/suggestions/search`;
  return instance.post(url, {
    filters,
    last_batch_only: user_type === 'client' ? true : last_batch_only,
    $count: user_type === 'client' ? false : true,
    page_size: user_type === 'client' ? 30 : pagination?.pageSize || 10,
    offset: user_type === 'client' ? 0 : pagination?.offset || 0,
  });
}
