import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';

export const TenantApi = createApi({
  reducerPath: 'TenantApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['tenant', 'createTenant', 'tenantInsights', 'tenantDelete', 'tenantRefresh'],
  endpoints: (builder) => ({
    checkTenantExists: builder.query<any, void>({
      query: () => ({
        pathname: `${config.REACT_APP_API_VERSION}/maintenance/provision/check_org`,
        method: 'GET',
      }),
      providesTags: ['tenant'],
    }),
    createTenant: builder.mutation({
      query: () => ({
        pathname: `${config.REACT_APP_API_VERSION}/maintenance/provision/init_tenant`,
        method: 'PUT',
      }),
      invalidatesTags: ['createTenant', 'tenant'],
    }),
    getTenantInsights: builder.query<any, void>({
      query: () => ({
        pathname: `${config.REACT_APP_API_VERSION}/maintenance/provision/insights`,
        method: 'GET',
      }),
      providesTags: ['tenantInsights'],
    }),
    deleteTenant: builder.mutation({
      query: () => ({
        pathname: `${config.REACT_APP_API_VERSION}/maintenance/provision/drop`,
        method: 'DELETE',
      }),
      invalidatesTags: ['tenantInsights', 'tenant', 'tenantDelete'],
    }),
    refreshTenant: builder.mutation({
      query: ({ countries, apiVersion }) => ({
        pathname: `${config.REACT_APP_API_VERSION}/maintenance/provision/refresh`,
        method: 'PUT',
        data: {
          countries,
          version: apiVersion,
        },
      }),
      invalidatesTags: ['tenantRefresh', 'tenantInsights', 'tenant'],
    }),
  }),
});

export const {
  useCheckTenantExistsQuery,
  useCreateTenantMutation,
  useGetTenantInsightsQuery,
  useDeleteTenantMutation,
  useRefreshTenantMutation,
} = TenantApi;
