"use strict";
import { jsx } from "react/jsx-runtime";
import "./App.css";
import { LoginProvider } from "./common/pages/login/login-context";
import { ToastProvider } from "./administration/common/components/toast/ToastPovider";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import ThemeWrapper from "./common/theme";
import router from "./routes";
import { store } from "./store";
function App() {
  return /* @__PURE__ */ jsx(Provider, { store, children: /* @__PURE__ */ jsx(ToastProvider, { children: /* @__PURE__ */ jsx(LoginProvider, { children: /* @__PURE__ */ jsx(ThemeWrapper, { children: /* @__PURE__ */ jsx(RouterProvider, { router }) }) }) }) });
}
export default App;
